.slick-slider {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.slick-list {
  position: relative;
  display: block !important;
  overflow: hidden;
  padding: 100px !important;
  width: 100%;

}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.slick-slide div {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slick-slide .image {
  object-fit: cover;
  border-radius: 2px;
  min-width: 150px !important;
  width: 80%;
  border-radius: 50%;
  
}
.slick-slide div div span {
  margin-top: 10px;
  width: inherit;
  text-align: center;
  font-size: 0.75rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #111;
}
@media screen and (max-width: 580px) {
  .slick-slide div div span {
    display: none;
  }
  .slick-slide.slick-current div div span {
    display: block;
    margin-top: 10px;
    width: inherit;
    text-align: center;
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #111;
  }
}
.slick-slide:not(.slick-active) {
  position: relative;
  transform: scale(0.5);
  z-index: 997;
}
.slick-slide.slick-active:not(.slick-current) {
  position: relative;
  z-index: 998;
  transform: scale(0.7);
  transition: 0.25s;
}
.slick-slide.slick-active.slick-current {
  position: relative;
  z-index: 999;
  
  transition: 0.25s;
}
.slick-slide.slick-active:not(.slick-current) .image,
.slick-slide.slick-active.slick-current .image,
.slick-slide:not(.slick-active) .image {
  width: 100% !important;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.custom-prevArrow,
.custom-nextArrow {
  position: relative;
  z-index: 99999;
  top: -10px;
  fill: white;
}
.custom-prevArrow {
  left: -10px;
}
.custom-nextArrow {
  right: -10px;
}
.custom-prevArrow:hover,
.custom-nextArrow:hover {
  fill: red;
  cursor: pointer;
}
